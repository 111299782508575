const moment = require('moment');

export const fields = {
  status: {
    loading: false,
    disabled: false,
    label: 'drivers.control.status',
  },
  photo: {
    loading: false,
    disabled: false,
    label: 'drivers.personal.photo',
  },
  signedupAt: {
    loading: false,
    disabled: false,
    label: 'drivers.control.signedup_at',
  },
  buzzer: {
    mask: 'XXXX XXXX XXXX XXXX',
    loading: false,
    disabled: false,
    label: 'drivers.control.buzzer',
    changed: false
  },
  fullname: {
    loading: false,
    disabled: false,
    label: 'drivers.personal.fullname',
    changed: false,
    messages: ''
  },
  email: {
    mask: '',
    loading: false,
    disabled: false,
    label: 'drivers.personal.email',
    changed: false,
    messages: ''
  },
  cpf: {
    mask: '###.###.###-##',
    loading: false,
    disabled: false,
    label: 'drivers.personal.social_security',
    changed: false,
    messages: ''
  },
  cnh: {
    mask: '###########',
    loading: false,
    disabled: false,
    label: 'drivers.personal.drivers_license',
    changed: false,
    messages: ''
  },
  rg: {
    id: {
      mask: '########-#',
      loading: false,
      disabled: false,
      label: 'drivers.personal.id_number',
      maxLength: 15,
      rules: [v => v != null && v.length <= 15 || 'Deve conter até 15 caracteres.'],
      changed: true,
      messages: ''
    },
    issuer: {
      loading: false,
      disabled: false,
      label: 'drivers.personal.id_issuer',
      changed: true,
      messages: ''
    }
  },
  phone: {
    mask: '+## (##) #####-####',
    loading: false,
    disabled: false,
    label: 'drivers.personal.phone',
    hint: 'DDD + Telefone',
    changed: false,
    messages: ''
  },
  birthdate: {
    mask: '##/##/####',
    loading: false,
    disabled: false,
    label: 'drivers.personal.birthdate',
    hint: 'dd/mm/aaaa',
    changed: false,
    messages: ''
  },
  gender: {
    loading: false,
    disabled: false,
    label: 'drivers.personal.gender.label',
    changed: false,
    items: [
      {
        text: 'drivers.personal.gender.feminine',
        value: 'F'
      },
      {
        text: 'drivers.personal.gender.masculine',
        value: 'M'
      },
      {
        text: 'drivers.personal.gender.undisclosed',
        value: '-'
      },
    ]
  },
  gps: {
    toggle: true,
    loading: false,
    disabled: true,
    label: 'drivers.app.gps',
  },
  push: {
    toggle: true,
    loading: false,
    disabled: true,
    label: 'drivers.app.push',
  },
  rating: {
    value: null,
    colors: ['red', 'amber', 'lime', 'secondary', 'primary'],
    toggle: true,
    loading: false,
    disabled: false,
    label: 'drivers.control.rating',
  },
  tags: {
    value: [],
    toggle: false,
    focus: false,
    loading: false,
    disabled: false,
    label: 'drivers.control.tags',
  },
  fleet: {
    value: null,
    toggle: true,
    loading: false,
    disabled: false,
    label: 'drivers.control.fleet',
  },
  job: {
    products: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'drivers.job.products.label',
      placeholder: 'drivers.job.products.placeholder',
      success: false,
      rules: [v => !!v && v!=null && v.length>0 || 'Campo obrigatório'],
      items: [
        {
          text: 'drivers.job.products.BUZZER_DIGITAL',
          value: 'BUZZER_DIGITAL'
        },
        {
          text: 'drivers.job.products.BUZZER_PRINT',
          value: 'BUZZER_PRINT'
        },
        {
          text: 'drivers.job.products.WRAPPING_WINDOW',
          value: 'WRAPPING_WINDOW'
        },
        {
          text: 'drivers.job.products.WRAPPING_BODYWORK',
          value: 'WRAPPING_BODYWORK'
        },
      ]
    },
    special_projects: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'drivers.job.special_projects.label',
      placeholder: 'drivers.job.special_projects.placeholder',
      success: false,
      rules: [v => v!=null || 'Campo obrigatório'],
      items: [
        {
          text: 'drivers.job.special_projects.yes',
          value: 1
        },
        {
          text: 'drivers.job.special_projects.no',
          value: 0
        },
      ],
    },
    apps: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'drivers.job.apps.label',
      placeholder: 'drivers.job.apps.placeholder',
      success: false,
      rules: [v => !!v && v!=null && v.length>0 || 'Campo obrigatório'],
      items: [
        // TODO: i18n
        {
          text: 'Uber',
          value: 'UBER'
        },
        {
          text: '99',
          value: '99'
        },
        {
          text: 'InDriver',
          value: 'INDRIVER'
        },
        {
          text: 'TAXI.RIO',
          value: 'TAXIRIO'
        },
        {
          text: 'Outros',
          value: 'Outros'
        },
        {
          text: 'Nenhum',
          value: 'Nenhum'
        },
      ]
    },
    apps_percent: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'drivers.job.apps_percent.label',
      placeholder: 'drivers.job.apps_percent.placeholder',
      success: false,
      rules: [v => !!v && v!=null && !Object.values(v).reduce((unset, app) => unset||app.value==null, false) || 'Campo obrigatório'],
      items: [],
      options: [
        10,
        20,
        30,
        40,
        50,
        60,
        70,
        80,
        90,
      ],
    },
    service_model: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'drivers.job.service_model.label',
      placeholder: 'drivers.job.service_model.placeholder',
      success: false,
      rules: [v => !!v || 'Campo obrigatório'],
      items: [
        {
          text: 'drivers.job.service_model.X',
          value: 'X'
        },
        {
          text: 'drivers.job.service_model.B',
          value: 'B'
        },
        {
          text: 'drivers.job.service_model.Xb',
          value: 'Xb'
        },
        {
          text: 'drivers.job.service_model.Bx',
          value: 'Bx'
        },
        {
          text: 'drivers.job.service_model.Tx',
          value: 'Tx'
        },
      ]
    },
    fulltime: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'drivers.job.fulltime.label',
      hint: 'drivers.job.fulltime.hint',
      success: false,
      rules: [v => v!=null || 'Campo obrigatório'],
      items: [
        {
          text: 'drivers.job.fulltime.full_time',
          value: 1
        },
        {
          text: 'drivers.job.fulltime.part_time',
          value: 0
        },
      ]
    },
    journey: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'drivers.job.journey.label',
      success: false,
      rules: [v => v!=null || 'Campo obrigatório'],
      items: [
        {
          text: 'drivers.job.journey.8-',
          value: 2
        },
        {
          text: 'drivers.job.journey.8-12',
          value: 1
        },
        {
          text: 'drivers.job.journey.12+',
          value: 0
        },
      ]
    }, 
    region: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'drivers.job.region.label',
      placeholder: 'drivers.job.region.placeholder',
      success: false,
      rules: [v => v!=null && v.length>=3 || 'Campo obrigatório'],
      items: [
        // TODO: i18n
        { header: 'RJ' },
        {
          title: 'Rio de Janeiro/Zona Sul',
          group: 'RJ'
        },
        {
          title: 'Rio de Janeiro/Zona Norte',
          group: 'RJ'
        },
        {
          title: 'Rio de Janeiro/Zona Oeste',
          group: 'RJ'
        },
        {
          title: 'Rio de Janeiro/Centro',
          group: 'RJ'
        },
        {
          title: 'Baixada',
          group: 'RJ'
        },
        {
          title: 'Niterói',
          group: 'RJ'
        },
        { header: 'Outras regiões' },
      ]
    },
    rating: {
      toggle: true,
      mask: '#.##',
      loading: false,
      disabled: false,
      label: 'drivers.job.rating.label',
      hint: 'drivers.job.rating.hint',
      success: false,
      rules: [v => !!v || 'Campo obrigatório',
        v => /^[0-9]*\.[0-9]{2}$/.test(v) || 'Ex: 4.90'
      ],
    },
    experience: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'drivers.job.experience.label',
      hint: 'drivers.job.experience.hint',
      max: moment().diff('2012-01-01', 'months'),
      rules: [v => v!=null || 'Campo obrigatório', v => v>0 || 'Campo obrigatório'],
      
    },
  },
  address: {
    street: {
      loading: false,
      disabled: false,
      label: 'drivers.personal.address.street',
      hint: 'drivers.personal.address.street_hint',
      changed: false,
      messages: ''
    },
    number: {
      loading: false,
      disabled: false,
      label: 'drivers.personal.address.number',
      hint: 'drivers.personal.address.number_hint',
      changed: false,
      messages: ''
    },
    compl: {
      loading: false,
      disabled: false,
      label: 'drivers.personal.address.compl',
      hint: 'drivers.personal.address.compl_hint',
      changed: false,
      messages: ''
    },
    neighborhood: {
      loading: false,
      disabled: false,
      label: 'drivers.personal.address.neighborhood',
      hint: '',
      changed: false,
      messages: ''
    },
    zip: {
      mask: '#####-###',
      loading: false,
      disabled: false,
      label: 'drivers.personal.address.zip_code',
      hint: '',
      changed: false,
      messages: ''
    },
    city: {
      loading: false,
      disabled: false,
      label: 'drivers.personal.address.city',
      hint: '',
      changed: false,
      messages: ''
    },
    state: {
      toggle: true,
      mask: 'AA',
      loading: false,
      disabled: false,
      label: 'drivers.personal.address.state',
      hint: '',
      success: false,
      rules: [v => !!v && v.length==2 || 'Campo obrigatório'],
      items: [
        'RO',
        'AC',
        'AM',
        'RR',
        'PA',
        'AP',
        'TO',
        'MA',
        'PI',
        'CE',
        'RN',
        'PB',
        'PE',
        'AL',
        'SE',
        'BA',
        'MG',
        'ES',
        'RJ',
        'SP',
        'PR',
        'SC',
        'RS',
        'MS',
        'MT',
        'GO',
        'DF',
      ]
    },
    country: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'drivers.personal.address.country',
      hint: '',
      success: false,
      rules: [v => !!v && v.length>=3 || 'Campo obrigatório'],
    },
  },
  vehicle: {
    rental: {
      loading: false,
      disabled: false,
      label: 'rental',
      messages: ''
    },
    parking: {
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.parking.label',
      items: [
      {
        text: 'drivers.vehicle.parking.covered_garage',
        value: 0
      },
      {
        text: 'drivers.vehicle.parking.uncovered_garage',
        value: 1
      },
      {
        text: 'drivers.vehicle.parking.street',
        value: 2
      },
    ]
    },
    plate: {
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.plate',
      messages: ''
    },
    chassis: {
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.chassis',
      changed: false,
      messages: ''
    },
    color: {
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.color.label',
      changed: false,
      messages: '',
      items: [
        {
          text: 'drivers.vehicle.color.black',
          value: 'black',
        },
        {
          text: 'drivers.vehicle.color.silver',
          value: 'silver',
        },
        {
          text: 'drivers.vehicle.color.white',
          value: 'white',
        },
        {
          text: 'drivers.vehicle.color.grey',
          value: 'grey',
        },
        {
          text: 'drivers.vehicle.color.dark_grey',
          value: 'dark_grey',
        },
        {
          text: 'drivers.vehicle.color.yellow',
          value: 'yellow',
        },
        {
          text: 'drivers.vehicle.color.red',
          value: 'red',
        },
        {
          text: 'drivers.vehicle.color.blue',
          value: 'blue',
        },
        {
          text: 'drivers.vehicle.color.dark_blue',
          value: 'dark_blue',
        },
        {
          text: 'drivers.vehicle.color.green',
          value: 'green',
        },
        {
          text: 'drivers.vehicle.color.gold',
          value: 'gold',
        },
        {
          text: 'drivers.vehicle.color.orange',
          value: 'orange',
        },
        {
          text: 'drivers.vehicle.color.brown',
          value: 'brown',
        },
        {
          text: 'drivers.vehicle.color.purple',
          value: 'purple',
        },
        {
          text: 'drivers.vehicle.color.pink',
          value: 'pink',
        },
      ]
    },
    fuel: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.fuel.label',
      placeholder: 'drivers.vehicle.fuel.placeholder',
      success: false,
      rules: [v => !!v && v!=null && v.length>0 || 'Campo obrigatório'],
      items: [
        {
          text: 'drivers.vehicle.fuel.natural_gas',
          value: 'natural_gas'
        }, 
        {
          text: 'drivers.vehicle.fuel.gasoline', 
          value: 'gasoline',
        },
        {
          text: 'drivers.vehicle.fuel.alcohol', 
          value: 'alcohol',
        },
        {
          text: 'drivers.vehicle.fuel.eletric',
          value: 'eletric',
        }
      ]
    },
    brand: {
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.brand',
      changed: false,
      messages: ''
    },
    model: {
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.model',
      changed: false,
      messages: '',
      items: [
        'Agile', 
        'Bolt', 
        'Celta', 
        'Classic',
        'Cobalt',
        'Corsa Classic',
        'Cruze',
        'Equinox',
        'Onix',
        'Onix +',
        'Prisma',
        'Spin',
        'Aircross',
        'C3',
        'C3 Picasso',
        'C4',
        'C4 Lounge',
        '500',
        'Argo',
        'Bravo',
        'Cronos',
        'Doblò',
        'Dolphin',
        'Dolphin Mini',
        'Grand Siena',
        'Idea',
        'Linea',
        'Mobi',
        'Palio',
        'Palio Weekend',
        'Punto',
        'Siena',
        'Toro',
        'Uno',
        'Fusion',
        'Edge',
        'EcoSport',
        'Fiesta',
        'Focus',
        'Ka',
        'Ka +',
        'Accord',
        'City',
        'Civic',
        'CR-V',
        'Fit',
        'HRV',
        'WRV',
        'Azera',
        'Creta',
        'Elantra',
        'HB20',
        'HB20S',
        'HB20X',
        'ix20',
        'ix35',
        'Santa Fé',
        'Sonata',
        'Tucson',
        'Veloster',
        'Veracruz',
        'J3',
        'J5',
        'Bongo',
        'Cadenza',
        'Carens',
        'Carnival',
        'Cerato',
        'Optima',
        'Picanto',
        'Quoris',
        'Rio',
        'Sorento',
        'Soul',
        'Sportage',
        'Stinger',
        'Pajero',
        'Lancer',
        'Grand Livina',
        'Kicks',
        'Leaf',
        'Livina',
        'March',
        'Sentra',
        'Tiida',
        'Versa',
        '2008',
        '207',
        '207 Passion',
        '208',
        '3008',
        '408',
        '5008',
        'Captur',
        'Clio',
        'Duster',
        'Fluence',
        'Kwid',
        'Logan',
        'Sandero',
        'Corolla',
        'Etios',
        'Prius',
        'Yaris',
        'Space Fox',
        'Fox',
        'Gol',
        'Golf',
        'Jetta',
        'Polo',
        'T-Cross',
        'Tiguan',
        'Up',
        'Virtus',
        'Voyage',
        'Outro'
      ],
    },
    year: {
      mask: '####',
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.year',
      changed: false,
      messages: ''
    },
    renavam: {
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.renavam',
      changed: false,
      messages: ''
    },
    isOwner: {
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.owner.label',
      success: false,
      rules: [v => v!=null || 'Campo obrigatório'],
      items: [
        {
          text: 'drivers.vehicle.owner.owner',
          value: 1
        },
        {
          text: 'drivers.vehicle.owner.borrowed',
          value: 0
        },
        {
          text: 'drivers.vehicle.owner.rental',
          value: 2
        },
      ]
    },
    ownerName: {
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.owner_name',
      changed: false,
      messages: ''
    },
    ownerRg: {
      mask: '########-#',
      loading: false,
      disabled: false,
      maxLength: 9,
      rules: [v => !!v && v != null && v.length > 6 || 'Campo obrigatório'],
      label: 'drivers.vehicle.owner_id',
      changed: false,
      messages: ''
    },
    ownerOrg_exp: {
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.owner_id_issuer',
      changed: false,
      messages: ''
    },
    ownerEmail: {
      mask: '',
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.owner_email',
      changed: false,
      messages: ''
    },
    ownerCpf: {
      mask: '###.###.###-##',
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.owner_social_security',
      changed: false,
      messages: ''
    },
    ownerRelative: {
      loading: false,
      disabled: false,
      label: 'drivers.vehicle.owner_relative',
      changed: false,
      messages: ''
    },
  },
  payment: {
    bankCode: {
      loading: false,
      disabled: false,
      label: 'drivers.payment.bank_code',
      changed: false
    },
    bank: {
      loading: false,
      disabled: false,
      label: 'drivers.payment.bank',
      changed: false
    },
    branch: {
      mask: ['###', '###-#', '###-##'], // mask freezes the browser
      loading: false,
      disabled: false,
      label: 'drivers.payment.branch',
      changed: false
    },
    account: {
      mask: ['###-#', '####-#', '#####-#', '######-#'], // mask freezes the browser
      loading: false,
      disabled: false,
      label: 'drivers.payment.account',
      hint: 'drivers.payment.account_hint',
      changed: false
    },
    digit: {
      loading: false,
      disabled: false,
      label: 'drivers.payment.account_digit',
      changed: false
    },
    type: {
      loading: false,
      disabled: false,
      label: 'drivers.payment.account_type.label',
      changed: false,
      items: [
        {
          text: 'drivers.payment.account_type.checking',
          value: 'COR'
        },
        {
          text: 'drivers.payment.account_type.savings',
          value: 'POU'
        },
      ]
    },
    compl: {
      loading: false,
      disabled: false,
      label: 'drivers.payment.account_compl',
      changed: false
    },
  },
};

export const status = [
  { 
    text: 'Registrado',
    value: 'REG',
    disabled: true,
    mustbe: ['DEN']
  },
  {
    text: 'Legalizado', 
    value: 'ACC',
    disabled: true,
    mustbe: ['FDB', 'ENR', 'SIG', 'FDD', 'REA', 'SCH']
  },
  {
    text: 'Avaliado', 
    value: 'FDB',
    disabled: true,
    mustbe: ['ENR', 'FDD', 'REA', 'SIG']
  },
  {
    text: 'Selecionado', 
    value: 'ENR',
    disabled: true,
    mustbe: ['ACC', 'FDB', 'FDD', 'SIG']
  },
  {
    text: 'Aguardando Assinatura', 
    value: 'SIG',
    disabled: true,
    mustbe: ['REA', 'ENR', 'FDD']
  },
  {
    text: 'Documentação Enviada', 
    value: 'FDD',
    disabled: true,
    mustbe: ['ENR', 'REA']
  },
  {
    text: 'Preparado', 
    value: 'REA',
    disabled: true,
    mustbe: ['FDB', 'SCH', 'ENR', 'ACT']
  },
  { 
    text: 'Agendamento',
    value: 'SCH',
    disabled: true,
    mustbe: ['REA', 'FDD', 'ACT']
  },
  { 
    text: 'Operando',
    value: 'ACT',
    disabled: true,
    mustbe: []
  },
  { 
    text: 'Desistente',
    value: 'FOR',
    disabled: true,
    mustbe: []
  },
  { 
    text: 'Bloqueado',
    value: 'DEN',
    disabled: true,
    mustbe: null
  },
]

export const docs = {
  'doc_cnh': {
    title: 'drivers.docs.drivers_license',
    example: 'doc_cnh.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
  'doc_app_perfil': {
    title: 'drivers.docs.app_profile',
    example: 'doc_app_perfil.jpg',
    loading: false,
    success: false,
  },
  'doc_app_horas1': {
    title: 'drivers.docs.hours_screenshot_1',
    example: 'doc_app_horas.jpg',
    loading: false,
    success: false,
  },
  'doc_app_horas2': {
    title: 'drivers.docs.hours_screenshot_2',
    example: 'doc_app_horas.jpg',
    loading: false,
    success: false,
  },
  'doc_app_horas3': {
    title: 'drivers.docs.hours_screenshot_3',
    example: 'doc_app_horas.jpg',
    loading: false,
    success: false,
  },
  'doc_app_horas4': {
    title: 'drivers.docs.hours_screenshot_4',
    example: 'doc_app_horas.jpg',
    loading: false,
    success: false,
  },
  'doc_veiculo': {
    title: 'drivers.docs.vehicle_registration',
    example: 'doc_veiculo.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
  'doc_veiculo_compra': {
    title: 'drivers.docs.vehicle_ownership_doc',
    example: 'doc_veiculo.jpg',
    loading: false,
    success: false,
  },
  'doc_residencia': {
    title: 'drivers.docs.proof_residence',
    example: 'doc_residencia.jpg',
    loading: false,
    success: false,
  },
  'doc_foto_veiculo1': {
    title: 'drivers.docs.vehicle_photo_1',
    example: 'doc_foto_veiculo1.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
  'doc_foto_veiculo2': {
    title: 'drivers.docs.vehicle_photo_2',
    example: 'doc_foto_veiculo2.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
  'doc_foto_veiculo3': {
    title: 'drivers.docs.vehicle_photo_3',
    example: 'doc_foto_veiculo3.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
  'doc_foto_veiculo4': {
    title: 'drivers.docs.vehicle_photo_4',
    example: 'doc_foto_veiculo4.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
  'doc_rg_prop': {
    title: 'drivers.docs.owner_id',
    example: 'doc_rg.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
  'doc_cpf_prop': {
    title: 'drivers.docs.owner_social_security',
    example: 'doc_cpf.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
}

export default {
  fields,
  status,
  docs
}